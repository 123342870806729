import React, { useEffect } from 'react';

import { getPortalSignUpStudentUrl } from '../utils/urls';

const StudentsPage: React.FC = () => {
  // Redirect to student sign-up page
  useEffect(() => {
    window.location.replace(getPortalSignUpStudentUrl());
  }, []);

  return <div />;
};

export default StudentsPage;
